<template>
<div class=" ">
      <div class="">
      <div class="fy-width_100p fy-height_50px tac fy-padding-10 fy-font-22 fy-portlet-active--bs2" @click="puff1">permission</div>
       <div class="fy-width_100p fy-height_50px tac fy-padding-10 fy-font-22 fy-portlet-active--bs2" @click="puff2">start</div>
       <div class="fy-width_100p fy-height_50px tac fy-padding-10 fy-font-22 fy-portlet-active--bs2" @click="puff3">stop</div>
       {{feel}}
        <audio v-if="src" :src="src" class="fy-width_100p" controls/>
      </div>
      <div class="fy-width_100p fy-margin-t-10" >
        <video style="width:100%;height:230px" ref="video" />
      <AVMedia :media="stream" type="wform" :fft-size="512" :line-width="0.8"/>
      </div>
      </div>
</template>
<script>
import { VoiceRecorder } from 'capacitor-voice-recorder';
import { AVMedia } from 'vue-audio-visual'
export default {
    name: 'HelloWorld',
    data() {
        return {
     pay : '',
     path : '',
     src : '',
     feel : '',
     stream : null
        }
    },
    mounted () {
      
    },
    components:{
      AVMedia
    },
     methods:{
  async puff1(){
  VoiceRecorder.requestAudioRecordingPermission().then((result) => this.feel = result.value)

  },
  async puff2(){
  VoiceRecorder.startRecording()
.then((result) => this.feel = result.value)
.catch(error => console.log(error))
//harshit

const constraints = { audio: true, video: true }
     navigator.mediaDevices.getUserMedia(constraints).
        then(media => {
          this.stream = media,
          this.$refs.video.srcObject = media
          
        })
  },
  async puff3(){
VoiceRecorder.stopRecording()
.then((result) => this.p(result.value))
.catch(error => console.log(error))
 

  },
  p(a){
   this.src =  `data:${a.mimeType};base64,${a.recordDataBase64}`;
    console.log(this.src);
  }

  },
}
</script>